<template>
  <v-card>
    <v-toolbar flat>
      <v-app-bar-nav-icon>
        <v-btn title="voltar" icon @click="$router.go(-1)">
          <v-icon>mdi-keyboard-backspace</v-icon>
        </v-btn>
      </v-app-bar-nav-icon>

      <v-card-title> Pagar / Visualizar cobrança </v-card-title>
    </v-toolbar>

    <div v-if="loading">
      <div class="text-center">
        <v-progress-circular
          indeterminate
          color="primary"
        ></v-progress-circular>
      </div>
      <div class="text-center pa-4">Carregando dados da cobrança...</div>
    </div>

    <div class="pa-4" v-else>
      <h3 class="mt-4">Detalhes da cobrança N. {{ charge.id }}</h3>

      <div class="charge-details mt-4">
        <div>
          <strong>Data de vencimento:</strong>
          {{ $moment(charge.dueDate).format("DD/MM/YYYY") }}
        </div>
        <div>
          <strong>Status: </strong>
          <v-chip x-small :color="statusColor(charge.status)">
            {{ statusName(charge.status) }}
          </v-chip>
        </div>
        <div><strong>Valor total:</strong> {{ chargeValue }}</div>
      </div>

      <h4 class="mt-4">Itens da cobrança</h4>

      <div class="charge-items mt-4">
        <v-data-table
          :headers="tableHeaders"
          :items="charge.Announcements"
          disable-pagination
          hide-default-footer
          class="elevation-1"
        >
          <template v-slot:[`item.actions`]="{ item }">
            <v-btn
              icon
              class="mr-2"
              title="Deletar anúncio"
              @click="deleteAd(item.id)"
              :disabled="charge.status !== 'pending'"
            >
              <v-icon>mdi-delete</v-icon>
            </v-btn>
          </template>
        </v-data-table>
      </div>
    </div>

    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn
        color="success"
        v-if="paymentMethodsVisibility"
        large
        @click="pay()"
        :loading="loading"
      >
        <v-icon left>mdi-cash</v-icon> Efetuar Pagamento</v-btn
      >
    </v-card-actions>
  </v-card>
</template>

<script>
import errorHandler from "@/helpers/error_handler";
import { baseUrl } from "@/config/config.json";

export default {
  components: {},
  data: () => ({
    loading: false,
    tab: null,
    charge: null,
    tableHeaders: [
      {
        text: "#ID",
        value: "id",
      },
      {
        text: "Plano",
        value: "Plan.name",
        sortable: false,
      },
      {
        text: "Período de pagamento",
        value: "TimeIntervalForPayment.name",
        sortable: false,
      },
      {
        text: "Categoria anunciada",
        value: "AdCategory.name",
        sortable: false,
      },
      {
        text: "Ações",
        value: "actions",
        sortable: false,
      },
    ],
  }),
  methods: {
    async init() {
      try {
        this.loading = true;

        await this.loadCharge();
      } catch (error) {
        console.error(error);
        const errorHandled = errorHandler.treatError(error);

        this.$root.$errorDialog(errorHandled);
      } finally {
        this.loading = false;
      }
    },
    async deleteAd(adId) {
      try {
        if (
          !(await this.$root.$confirm(
            "Atenção!",
            "Deseja realmente excluir o anúncio desta cobrança e desativá-lo ? Isto fará seu anúncio parar de ser exibido"
          ))
        )
          return;

        this.loading = true;

        const chargeId = this.$route.params.chargeId;

        await this.$axios.delete(
          `/customers/charges/${chargeId}/posts/${adId}`
        );

        await this.loadCharge();
      } catch (error) {
        console.error(error);

        const errorHandled = errorHandler.treatError(error);

        this.$root.$errorDialog(errorHandled);
      } finally {
        this.loading = false;
      }
    },
    async loadCharge() {
      const chargeId = this.$route.params.chargeId;

      const response = await this.$axios.get(`/customers/charges/${chargeId}`);

      this.charge = response.data;
    },
    pay() {
      const chargeId = this.$route.params.chargeId;

      window.open(`${baseUrl}/customers/charges/${chargeId}/pay`, "_blank");
    },
    statusColor(status) {
      switch (status) {
        case "pending":
          return "warning";

        case "paid":
          return "success";

        case "processing-payment":
          return "info";

        case "payment-error":
          return "error";

        case "canceled":
          return "gray";

        default:
          return "white";
      }
    },
    statusName(status) {
      switch (status) {
        case "pending":
          return "Pendente";

        case "paid":
          return "Pago";

        case "canceled":
          return "Cancelado";

        case "processing-payment":
          return "Pagamento em processamento";

        case "payment-error":
          return "Erro no pagamento";

        default:
          return "Status desconhecido";
      }
    },
  },
  computed: {
    chargeValue() {
      return parseFloat(this.charge.value).toLocaleString("pt-BR", {
        style: "currency",
        currency: "BRL",
      });
    },
    paymentMethodsVisibility() {
      return (
        this.charge &&
        (this.charge.status === "payment-error" ||
          this.charge.status === "pending")
      );
    },
  },
  created() {
    this.init();
  },
};
</script>

<style scoped>
.checkout-summary {
  background-color: rgb(224, 224, 224);
}
</style>
